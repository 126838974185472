import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ContactUsMap from "../components/ContactUsMap";
import CommunityAbout from "../components/CommunityAbout";
import ArticleHeroImage from "../components/ArticleHeroImage";
import {
  ArticleTitleWrapper,
  EventBody,
  QAFMapWrapper,
} from "../components/AboutStyles/elements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AboutPage = () => {
  const aboutQuery = useStaticQuery(graphql`
    {
      global: craftSiteQafSettingsGlobalSet {
        whatsAppPhone
        globalPhoneNumber
        globalEmailAddress
        siteQAFSettingWebsite
        siteQAFSettingOpeningHours
        siteQAFSettingAddress
        siteQAFSettingMapButtonLink
        siteQAFSettingMapButtonText
        siteSettingsId
      }

      craftPagesQafDefaultEntry(slug: { eq: "about-qaf" }) {
        title
        pageQAFDescriptionOne
        pageQAFDescriptionTwo
        sectionPageQAFContent {
          ... on Craft_sectionPageQAFContent_bannerImages_BlockType {
            uploadImages {
              ... on Craft_qafAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    whatsAppPhone,
    globalPhoneNumber,
    globalEmailAddress,
    siteQAFSettingWebsite,
    siteQAFSettingOpeningHours,
    siteQAFSettingAddress,
    siteQAFSettingMapButtonLink,
    siteQAFSettingMapButtonText,
  } = aboutQuery.global;
  const { title, pageQAFDescriptionOne, pageQAFDescriptionTwo } =
    aboutQuery.craftPagesQafDefaultEntry;

  const getImg = (index) => {
    return aboutQuery?.craftPagesQafDefaultEntry?.sectionPageQAFContent?.length
      ? aboutQuery?.craftPagesQafDefaultEntry?.sectionPageQAFContent[index]
          ?.uploadImages?.length
        ? aboutQuery?.craftPagesQafDefaultEntry?.sectionPageQAFContent[index]
            ?.uploadImages[0]?.localFile?.childImageSharp
        : null
      : null;
  };

  const img1 = getImg(0);
  const img2 = getImg(1);
  const img3 = getImg(2);
  const img4 = getImg(3);
  const img5 = getImg(4);

  return (
    <Layout
      backgroundColor={"var(--black)"}
      skipHeader
      headerBlack={true}
    >
      <ArticleTitleWrapper background={img1?.fixed?.src}>
        <AnimationOnScroll
          animateIn="animate__backInLeft"
          animateOnce
          offset={50}
          // animateOut="animate__fadeOutLeft"
          // duration={0.75}
        >
          <h1>{title}</h1>
        </AnimationOnScroll>
      </ArticleTitleWrapper>

      <ArticleHeroImage
        color={"var(--white)"}
        carouselImages={[img2, img3, img4]}
        useCarousel
        imagesAreUrls={true}
      />

      <EventBody dangerouslySetInnerHTML={{ __html: pageQAFDescriptionOne }} />

      <QAFMapWrapper background={img5?.fixed?.src}>
        <ContactUsMap center={{ lat: 25.141861, lng: 55.226111 }} zoom={17} />
      </QAFMapWrapper>

      <CommunityAbout
        communityMemberContactDetails={[
          { method: "Whatsapp", value: whatsAppPhone },
          { method: "Phone", value: globalPhoneNumber },
        ]}
        communityMemberDescription={pageQAFDescriptionTwo}
        communityMemberWebsite={siteQAFSettingWebsite}
        communityMemberEmailAddress={globalEmailAddress}
        communityMemberLocation={siteQAFSettingAddress.split(",")}
        communityMemberOpeningHours={siteQAFSettingOpeningHours}
        communityButtonText={siteQAFSettingMapButtonText}
        communityButtonLink={siteQAFSettingMapButtonLink}
      />
    </Layout>
  );
};

export default AboutPage;
